import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as projectStyles from "../components/project.module.css"
import { Link } from "gatsby"
import Video from "../videos/gasthauslinde-30-cropped.mp4"
import videoMobile from "../videos/naturrepublik-30-cropped.mp4"
import videoTablet from "../videos/tablet-screen-recording.mp4"

const GasthauslindePage = () => (
  <div>
    <Seo title="Webdesign für Gasthaus Linde Großerlach - Altergott Studios" lang="de" description="Eine neue Website wurde für das mehr als 200 Jahre alte Gasthaus in Großerlach erstellt, um das Angebot besser zu kommunizieren und mehr neue Kunden zu gewinnen."/>

    <Layout>
      <div>
        <Link to="https://www.gasthauslinde-grosserlach.de/">
          <div className={projectStyles.videoWrapper}>
            <video
              loop
              muted
              autoPlay
              playsInline
              className={projectStyles.video}
            >
              <source src={Video} type="video/mp4" />
            </video>
            <video
              loop
              muted
              autoPlay
              playsInline
              className={projectStyles.videoMobile}
            >
              <source src={videoMobile} type="video/mp4" />
            </video>
            <video
              loop
              muted
              autoPlay
              playsInline
              className={projectStyles.videoTablet}
            >
              <source src={videoTablet} type="video/mp4" />
            </video>
          </div>
        </Link>

        <div className={projectStyles.projectGrid}>
          <div>
            <h3 className={projectStyles.uppercase}>Kunde</h3>

            <p>Gasthaus Linde</p>
            <Link
              to="https://www.gasthauslind-grosserlach.com/"
              className={projectStyles.projectLink}
            >
              gasthauslinde-grosserlach.com
            </Link>
          </div>
          <div>
            <h3 className={projectStyles.uppercase}>Projektbeschreibung</h3>

            <p>Leistungen: Webdesign, SEO, Hosting, Domainregistrierung</p>
            <p>
              Eine neue Website wurde für das mehr als 200 Jahre alte Gasthaus in Großerlach erstellt, um das Angebot besser zu kommunizieren und mehr neue Kunden zu gewinnen.
            </p>
            <p>Ein Kontaktformular ermöglicht Interessierten das Gasthaus zu kontaktieren, sei es um einen Tisch zu reservieren, eine Veranstaltung zu organisieren oder die Catering Dienstleistungen in Anspruch zu nehmen.</p>
            <p>Eine Facebook Einbindung ermöglicht Besuchern das Gasthaus auf Facebook zu folgen, zu liken oder eine Nachricht zu verschicken.</p>
          </div>
          <div>
            <h3 className={projectStyles.uppercase}>Auswirkung</h3>

            <p>
              Die neue Website hilft dem Gasthaus wichtige Informationen wie Öffnungszeiten, das Menü oder Aktionen zum bestehenden Kundenstamm, sowie zu potentiellen neuen Kunden zu kommunizieren.
            </p>
            <p>Einladende Bilder überzeugen die Besucher der Website das Gasthaus zu besuchen.</p>
            <p>Das markentreue Design stärkt die Marke und den Auftritt des Gasthauses gegenüber Mitarbeitern und Kunden.</p>
          </div>
        </div>
      </div>
    </Layout>
  </div>
)

export default GasthauslindePage
