// extracted by mini-css-extract-plugin
export var projectTitle = "project-module--projectTitle--3flcT";
export var uppercase = "project-module--uppercase--2q0py";
export var carousel = "project-module--carousel--3mTKu";
export var projectServices = "project-module--projectServices--1EGeU";
export var carouselWrapper = "project-module--carouselWrapper--2K899";
export var videoWrapper = "project-module--videoWrapper--9q38z";
export var video = "project-module--video--2xetj";
export var videoMobile = "project-module--videoMobile--aqMqr";
export var videoTablet = "project-module--videoTablet--SNgcX";
export var projectGrid = "project-module--projectGrid--OVvqd";
export var services = "project-module--services--17h3n";
export var projectLink = "project-module--projectLink--3KOZC";